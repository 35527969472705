export const RUSSIAN_TRANSLATIONS: Record<string, string> = {
  downloadDefrag: 'Скачать Defrag',
  author: 'Автор',
  ratingLeaders: 'Лидеры рейтинга',
  player: 'Игрок',
  rating: 'Рейтинг',
  loggedInAs: 'Вы вошли как',
  adminPanel: 'Админка',
  profile: 'Профиль',
  logout: 'Выйти',
  logIn: 'Войти',
  signUp: 'Зарегистрироваться',
  mainPage: 'Главная',
  rules: 'Правила',
  movies: 'Мувики',
  comments: 'Комментарии',
  hideComments: 'Скрыть комментарии',
  writeComment: 'Написать комментарий',
  send: 'Отправить',
  loginOrRegisterToComment: 'Войдите или зарегистрируйтесь, чтобы оставить комментарий',
  map: 'Карта',
  start: 'Старт',
  finish: 'Финиш',
  weapons: 'Оружия',
  download: 'Скачать',
  fullRules: 'Полные правила',
  config: 'Конфиг',
  upload: 'Загрузить',
  cupFinished: 'Турнир завершен',
  cupNotStarted: 'Турнир еще не начался',
  loginOrRegister: 'Войдите или зарегистрируйтесь',
  noUploadedDemos: 'Нет загруженных демок',
  time: 'Тайм',
  allDemosArchive: 'Скачать все демки одним архивом',
  fullTable: 'Общая таблица',
  myDemos: 'Мои демки',
  delete: 'Удалить',
  confirmDelete: 'Вы подтверждаете удаление?',
  demoValidationFailed: 'Демо не прошло валидацию',
  parameter: 'Параметр',
  actualValue: 'Текущее значение',
  requiredValue: 'Требуемое значение',
  listOfRegisteredPlayers: 'Список зарегистрированных участников',
  errorNewsDoesntExist: 'Ошибка - такой новости не существует',
  season: 'Сезон',
  ratingsBySeason: 'Рейтинги по сезонам',
  youCanWatchFullRatingSystemDescription: 'Посмотреть полное описание рейтинговой системы можно',
  here: 'здесь',
  currentSeasonRatingList: 'Рейтинг-лист игроков: текущий сезон',
  seasonRatingList: 'Рейтинг-лист игроков: сезон',
  defragMovies: 'Мувики по дефрагу',
  country: 'Страна',
  playerProfile: 'Профиль игрока',
  rewards: 'Награды',
  lastDemos: 'Последние демки',
  tournamentParticipation: 'Участие в турнирах',
  tournamentName: 'Название турнира',
  physics: 'Физика',
  result: 'Результат',
  place: 'Место',
  siteLogIn: 'Вход на сайт',
  userLogin: 'Логин',
  password: 'Пароль',
  wrongLoginPassword: 'Неверный логин/пароль',
  signUpOnSite: 'Регистрация на сайте',
  fieldIsRequired: 'Поле не заполнено',
  confirmation: 'Подтверждение',
  passwordsDoNotMatch: 'Пароли не совпадают',
  wrongEmailFormat: 'Неверный формат e-mail',
  loginAlreadyTaken: 'Логин уже занят',
  bonusRating: 'Бонусный рейтинг',
  daysShorthand: 'Дн',
  starting: 'Начало',
  awaitingTournamentStart: 'Ожидание начала турнира',
  finished: 'Закончен',
  downloadMap: 'Скачать карту',
  finishing: 'Окончание',
  error: 'Ошибка',
  noDemo: 'Нет демки',
  wrongMap: 'Неправильная карта',
  uploadFailed: 'Загрузка не удалась',
  success: 'Успешно',
  demoSent: 'Демо отправлено',
  archive: 'Новости',
  allNews: 'Все новости',
  newsArchive: 'Архив новостей',
  goToArchive: 'Перейти в архив новостей',
  fullResultsTable: 'Полная таблица результатов',
  editProfile: 'Редактировать профиль',
  nick: 'Ник',
  nickChangeMessage: 'Может быть изменен раз в месяц, логин остается прежним',
  avatar: 'Аватар',
  avatarChangeMessage: 'Макс. размер - 5MB',
  save: 'Сохранить',
  registerForCup: 'Зарегистрироваться',
  cancelRegistration: 'Отменить регистрацию',
  returnToMainpage: 'На главную',
  cancel: 'Отменить',
  editComment: 'Редактировать комментарий',
  twoMinutesError: 'Прошло более 2 минут',
  cantModify: `Нельзя редактировать`,
  fillDeleteReason: 'Заполните причину удаления коммента',
  reason: 'Причина',
  edit: 'Редактировать',
  commentDeletedByModerator: 'Комментарий удален модератором. Причина:',
  personalSmiles: 'Персональные',
  defragSmiles: 'Defrag',
  mainSmiles: 'Смайлы',
  gestureSmiles: 'Жесты',
  foodSmiles: 'Еда',
  animalsSmiles: 'Животные',
  miscSmiles: 'Разное',
  noSmilesFound: 'Смайлов не найдено',
  chosenSmile: 'Выбранный смайл',
  searchSmiles: 'Поиск смайлов',
  oneVOne: '1v1',
  oneVOneMatches: 'Матчи 1v1',
  waitingForQueue: 'Ожидание очереди',
  playOneVersusOne: 'Играть 1v1',
  inQueue: 'В очереди',
  searchingForOpponent: 'Поиск соперника',
  cancelSearch: 'Отменить поиск',
  playNextMatch: 'Следующий матч',
  matchesPageOpenedElsewhere: 'Страница открыта во второй вкладке',
  waitingForServerConnection: 'Ожидание соединения с сервером',
  registerToPlayMatches: 'Войдите или зарегистрируйтесь, чтобы играть матчи 1v1',
  matchInProgress: 'Матч идет',
  opponentIsBanningMap: 'Соперник блокирует карту',
  youAreBanningMap: 'Вы блокируете карту',
  uploadDemoWhenReady: 'Загрузите демо',
  yourBestTime: 'Лучшее время',
  matchFinished: 'Матч завершен',
  wins: 'Побеждает',
  draw: 'Ничья',
  showPrepostedNews: 'Показать будущие новости (7 дней)',
  killobsWarning: 'Предупреждение: овербаунсы включены',
  findOnevOne: 'Найти матч 1v1',
  currentMatchesIn: 'Текущие матчи в',
  playersInQueue: 'Игроков в очереди',
  code: 'Код',
  uploadDemo: 'Загрузить демо',
  difficulty: 'Сложность',
  descriptionAndRules: 'Описание и правила',
  downloadForValidation: 'Скачать все демки для валидации',
  searchVQ3: 'Поиск VQ3',
  searchCPM: 'Поиск CPM',
  loginOrRegisterToPlay1v1: 'Войдите или зарегистрируйтесь, чтобы играть 1v1 матчи',
  logInWithPassword: 'Войти по паролю',
  logInWithDiscord: 'Войти через Discord',
  registerNewDiscordAccount: 'Зарегистрировать новый аккаунт Discord',
  discordAccountNotFound: 'К указанному аккаунту Discord не привязан аккаунт Dfcomps. Зарегистрировать новый аккаунт?',
  yes: 'Да',
  no: 'Нет',
  finishSignUp: 'Завершить регистрацию',
  signUpOnlyWithDiscord: 'Регистрация на сайте в данный момент доступна только через аккаунт Discord',
  registerWithDiscord: 'Зарегистрироваться через Discord',
  signUpLoginCaption: 'Выберите логин, под которым другие пользователи будут видеть вас на сайте',
  doYouWantToLinkDiscord: 'Хотите привязать Discord к своему профилю?',
  linkDiscord: 'Привязать Discord',
  downloadStreamersArchive: 'Скачать архив демок для стримеров',
  other: 'Остальные',
  openStreamersTimer: 'Открыть таймер для стрима',
  onlineCupMapAreNotSet: 'Карты онлайн-капа еще не установлены',
  invalidDemos: 'Невалидные демки',
  mapSuggestion: 'Предложить карту',
  suggestMapOnWarcup: 'Предложить карту на warcup',
  mapWasNotFoundOnWS: 'Карта не найдена на ws.q3df.org',
  mapWasOnCompetition: 'Карта была на',
  lessThanthreeYearsAgo: 'менее 3 лет назад',
  mapWasFoundOnWS: 'Карта найдена на ws.q3df.org',
  mapWasntOnCompetitions: 'Карта не была на турнирах dfcomps в последние 3 года',
  mapSuggested: 'Карта была отправлена как предложение на Warcup',
  suggestionGrayHint: `Будет доступно во время следующего Warcup'a`,
  seasonPeriod: '01 Июня 2024 - 30 Ноября 2024',
  forMappers: 'Для мапперов',
  mappersSuggestionInfo: 'если хотите предложить свою новую карту на warcup, пишите в ЛС в дискорд n0sf',
};
